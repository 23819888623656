import { Injectable, Component} from '@angular/core';
import { AngularFirestore} from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import { User } from '../../shared/models/user';
import { UserService } from '../../core/user/user.service';

@Injectable()
export class UsersService {
    user: any
    userList: any
    loading = false;
    constructor(
        public afs: AngularFirestore,
        public afAuth: AngularFireAuth,
        public userService: UserService
    ) {
        this.userList = afs.collection('users').valueChanges();
        this.user = afAuth.authState;
        console.log(this.user);
      }

    public getUsers(): Observable<User[]> {
        return this.userList;
    }

    public createUser(user) {
        const id = this.afs.createId()
        user.id = id
        user.status = 'Created';

        user.created = {
            uid: this.afAuth.auth.currentUser.uid,
            name: this.afAuth.auth.currentUser.displayName,
            time: new Date().getTime()
        };
        return (this.afs.collection('users').doc(id).set(user));
    }

    public resetPassword(user) {
        return this.afAuth.auth.sendPasswordResetEmail(user.email);
    }
    public updateUser(user) {
        user.updated = {
            uid: this.afAuth.auth.currentUser.uid,
            name: this.afAuth.auth.currentUser.displayName,
            time: new Date().getTime()
        };
        return (this.afs.collection('users').doc(user.id).update(user));
    }

}
