// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  cloudFunctions: 'https://secure.knowmyclient.com/api/',
  firebase: {
    apiKey: "AIzaSyAQAyyMpnx0h7ylIElBoLONyHEr1ROqs4o",
    authDomain: "knowmyclient-1501076246449.firebaseapp.com",
    databaseURL: "https://knowmyclient-1501076246449.firebaseio.com",
    projectId: "knowmyclient-1501076246449",
    storageBucket: "knowmyclient-1501076246449.appspot.com",
    messagingSenderId: "266064596925",
    appId: "1:266064596925:web:31e63b62761e70c057ccd4"
  }
  // production: false,
  // cloudFunctions: 'https://us-central1-knowmyclienttest.cloudfunctions.net/',
  // firebase: {
  //   apiKey: "AIzaSyDzyYHP6NkW8tdwmKbglQW4fvGQ8z7u5DY",
  //   authDomain: "knowmyclienttest.firebaseapp.com",
  //   databaseURL: "https://knowmyclienttest.firebaseio.com",
  //   projectId: "knowmyclienttest",
  //   storageBucket: "knowmyclienttest.appspot.com",
  //   messagingSenderId: "928891964975",
  //   appId: "1:928891964975:web:b09a64a65a3b26bfb900da"
  // }
};
